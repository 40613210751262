import React from 'react'
import styles from './style.module.sass'

export default ({ top }) => {
  return (
    <div className={styles.top}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{top.title}</h2>
        <p className={styles.description}>{top.description}</p>
        <p className={styles.quote}>{top.quote}</p>
      </div>
    </div>
  )
}
